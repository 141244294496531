import nanoid from 'nanoid';
import { differenceInDays, format, differenceInBusinessDays } from 'date-fns';

const countryCurrencies = {
  li: 'CHF',
  cz: 'CZK',
  dk: 'DKK',
  gb: 'GBP',
  hu: 'HUF',
  no: 'NOK',
  pl: 'PLN',
  ro: 'RON',
  se: 'SEK',
};

export function createEmptyInfoItem() {
  return {
    id: nanoid(5),
    amount: '1',
  };
}

function getDate(strDate) {
  return strDate ? new Date(strDate) : null;
}

export function formatDate(date) {
  return format(date, 'dd.MM.yyyy');
}

export function getCurrencyOfCountry(countryCode) {
  return countryCurrencies[countryCode] || 'EUR';
}

export function createClaimFromShipment(shipment) {
  return {
    shipmentId: shipment.id,
    trackingCode: shipment.trackingCode,
    carrierName: shipment.carrier.name,
    carrierCountry: shipment.carrier.country,
    carrierCode: shipment.carrier.code,
    carrierCountryCode: shipment.carrier.countryCode,
    customerName: shipment.customer.name,
    firstHubScanDate: getDate(shipment.firstHubScanDate),
    ssHubScanDate: getDate(shipment.ssHubScanDate),
    deliveryDate: getDate(shipment.deliveryDate),
    postalReturnDeliveryDate: getDate(shipment.postalReturnDeliveryDate),
    isDelivered: !!shipment.deliveryDate || !!shipment.postalReturnDeliveryDate,
    isReturnShipment: shipment.isReturnShipment,
    needsWarehouseDeliveryConfirmation: getClaimNeedsWarehouseDeliveryConfirmation(shipment),
    deliveryAttemptDate: getDate(shipment.deliveryAttemptDate),
    weight: shipment.weight || 1,
    netValueCurrency: getCurrencyOfCountry(shipment.customer.country.iso2),
    partialDamageInfo: [createEmptyInfoItem()],
    missingItemInfo: [createEmptyInfoItem()],
    trackingScreenshot: [],
    invoiceDocument: [],
    customerComment: '',
    declarationOfRecipient: [],
    picturesOfDamage: [],
    picturesOfMissingItem: [],
    recipientAddress: shipment.customer.address,
    recipientCity: shipment.customer.city,
    recipientZipCode: shipment.customer.zipCode,
    shopOrderId: shipment.order.shopOrderId,
    deadlines: shipment.deadlines,
  };
}

const eventTypeToClaimType = {
  SELECT_INVESTIGATION: 'investigation',
  SELECT_PARTIAL_DAMAGE: 'partial_damage',
  SELECT_WHOLE_DAMAGE: 'whole_damage',
  SELECT_MISSING_ITEM: 'missing_item',
};

export function isClaimOutsideOfDeadline(claim, event) {
  const claimType = eventTypeToClaimType[event.type] || claim.type;

  if (localStorage.getItem('deadlines-enabled') !== 'true') {
    return false;
  }

  const { outcome } = claim.deadlines?.[claimType] || {};

  // it's better to not compare against "within" because for manual flow we don't have deadlines
  return outcome === 'early' || outcome === 'past';
}

export function getClaimPastDeadlines(claim, isClaimsAutomated) {
  const {
    type: claimType,
    ssHubScanDate,
    firstHubScanDate,
    deliveryDate,
    isReturnShipment,
    postalReturnDeliveryDate,
    warehouseDeliveryDate,
  } = claim;

  const hubScanDate = firstHubScanDate || ssHubScanDate;

  if (!isClaimsAutomated || (isReturnShipment && !warehouseDeliveryDate))
    return {
      isInvestigationClaimPastDeadline: false,
      isDamageOrMissingItemClaimPastDeadline: false,
    };

  const investigationClaimClaimablePeriod = 25;
  const damageOrMissingItemClaimClaimablePeriod = 5;

  const isInvestigationClaim = claimType === 'investigation';
  const isDamageOrMissingItemClaim =
    claimType === 'partial_damage' || claimType === 'whole_damage' || claimType === 'missing_item';

  const now = new Date();
  const daysSinceHubScanDate = differenceInDays(now, new Date(hubScanDate));
  const daysSinceDeliveryDate = differenceInDays(
    now,
    new Date(warehouseDeliveryDate || deliveryDate || postalReturnDeliveryDate)
  );

  const isInvestigationClaimPastDeadline =
    isInvestigationClaim && daysSinceHubScanDate > investigationClaimClaimablePeriod;

  const isDamageOrMissingItemClaimPastDeadline =
    isDamageOrMissingItemClaim && daysSinceDeliveryDate > damageOrMissingItemClaimClaimablePeriod;

  return {
    isInvestigationClaimPastDeadline,
    isDamageOrMissingItemClaimPastDeadline,
  };
}

export const warehouseDeliveryDateCutoffDays = 4;

export function getClaimNeedsWarehouseDeliveryConfirmation(shipment) {
  const { deliveryDate, isReturnShipment, postalReturnDeliveryDate } = shipment;

  const now = new Date();

  if (isReturnShipment) {
    if (!deliveryDate) {
      return false;
    }

    const daysSinceDeliveryDate = differenceInBusinessDays(now, new Date(deliveryDate));
    return daysSinceDeliveryDate > warehouseDeliveryDateCutoffDays;
  }

  if (!postalReturnDeliveryDate) {
    return false;
  }

  const daysSincePostalReturnDeliveryDate = differenceInBusinessDays(now, new Date(postalReturnDeliveryDate));

  return daysSincePostalReturnDeliveryDate > warehouseDeliveryDateCutoffDays;
}
