import qs from 'qs';

import { Api } from './Api';

class TouchpointsApi {
  constructor({ baseUrl, token, shopId, handlers = {} }) {
    this.api = new Api(baseUrl, token, handlers);
    this.shopId = shopId;
  }

  setToken(token) {
    this.api.setToken(token);
  }

  setShopId(shopId) {
    this.shopId = shopId;
  }

  shopGet(path, params) {
    return this.api.get(`/shops/${this.shopId}${path}`, params);
  }

  shopPost(path, params) {
    return this.api.post(`/shops/${this.shopId}${path}`, params);
  }

  getUsers(shopId) {
    return this.api.get(`/shops/${shopId}/users`);
  }

  getSendingLogicStats(shopId) {
    return this.api.get(`/shops/${shopId}/sending-logic-stats`);
  }

  getCarrierCompanies() {
    return this.api.get('/carrier-companies');
  }

  getCarriers() {
    return this.api.get('/carriers');
  }

  parcelFinderSearch(search, searchBy) {
    return this.shopGet('/parcel-finder', { search, searchBy });
  }

  getShipmentWithClaimableInfoByTrackingCode(trackingCode) {
    return this.shopGet(`/claimable-shipments/${trackingCode}`);
  }

  getShipment(shopId, shipmentId) {
    return this.api.get(`/shops/${shopId}/shipments/${shipmentId}`);
  }

  getShipments(shopId, orderId) {
    return this.api.get(`/shops/${shopId}/orders/${orderId}/shipments`);
  }

  getOrder(shopId, orderId) {
    return this.api.get(`/shops/${shopId}/orders/${orderId}`);
  }

  submitClaim(type, claim) {
    return this.shopPost(`/claims/${type}`, claim);
  }

  submitClaimAutomated(type, claim) {
    return this.shopPost(`/claims/automated/${type}`, claim);
  }

  submitCustomerAction(claimId, customerAction) {
    return this.shopPost(`/claims/${claimId}/customer-actions`, customerAction);
  }

  /**
   * @typedef {Object} GetClaimsResponse
   * @property {array} claims
   * @property {number} offset
   * @property {number} limit
   * @property {number} total
   */

  /**
   * @param shopId
   * @param params
   * @return {Promise<GetClaimsResponse>}
   */
  getClaimsData({ shopId, ...params }) {
    const queryString = qs.stringify(params, { arrayFormat: 'comma' });

    return this.api.get(`/shops/${shopId}/claims?${queryString}`);
  }

  getClaimsStats() {
    return this.shopGet('/claims-stats');
  }

  getClaimsShopCarriers(shopId) {
    return this.api.get(`/shops/${shopId}/claims-carriers`);
  }

  getClaimsShopSettings(shopId) {
    return this.api.get(`/shops/${shopId}/claims-settings`);
  }

  getReturnPortals(shopId) {
    return this.api.get(`/shops/${shopId}/return-portals`);
  }

  createReturnPortal(shopId, data) {
    return this.api.post(`/shops/${shopId}/return-portals`, data);
  }

  getReturnPortal(shopId, portalHash) {
    return this.api.get(`/shops/${shopId}/return-portals/${portalHash}`);
  }

  isEmailAddressVerified(email) {
    return this.api.get(`/email-verified?email=${email}`);
  }

  verifyEmailAddress(emailAddress) {
    return this.api.post('/verify-email', { emailAddress });
  }

  updateReturnPortal(shopId, portalHash, data) {
    return this.api.patch(`/shops/${shopId}/return-portals/${portalHash}`, data);
  }

  deleteReturnPortal(shopId, portalHash) {
    return this.api.delete(`/shops/${shopId}/return-portals/${portalHash}`);
  }

  publishReturnPortal(shopId, portalHash) {
    return this.api.put(`/shops/${shopId}/return-portals/${portalHash}/published`);
  }

  unpublishReturnPortal(shopId, portalHash) {
    return this.api.delete(`/shops/${shopId}/return-portals/${portalHash}/published`);
  }

  createReturnShipments(shopId, { orderId, count }) {
    return this.api.post(`/shops/${shopId}/return-shipments`, { orderId, count });
  }

  sendTestReturnConfirmationEmail(shopId, data) {
    return this.api.post(`/shops/${shopId}/return-portals/test-email`, data);
  }

  saveBrandedUrl(shopId, hash, data) {
    return this.api.put(`/shops/${shopId}/return-portals/${hash}/branded-url`, data);
  }

  getAnalyticsDashboards(shopId) {
    return this.api.get(`/embeddables?shopId=${shopId}`);
  }

  getDashboard(id) {
    return this.api.get(`/dashboards/${id}`);
  }

  getLook(id) {
    return this.api.get(`/looks/${id}`);
  }

  getExplore(id) {
    return this.api.get(`/explores/${id}`);
  }

  permissions(shopId) {
    return this.api.get('/permissions', { shopId });
  }

  saveReturnPortalsTranslations(shopId, data) {
    return this.api.patch(`/shops/${shopId}/return-portals-translations`, data);
  }

  getReturnPortalsTranslations(shopId) {
    return this.api.get(`/shops/${shopId}/return-portals-translations`);
  }

  getClaimCustomerResponse(shopId, claimId) {
    return this.api.get(`/shops/${shopId}/claims/${claimId}/customer-response`);
  }

  createChatbotToken(shopId) {
    return this.api.post(`/shops/${shopId}/chatbot/token`, {});
  }

  submitCarrierClaimPayments(data) {
    return this.shopPost('/carrier-claim-payments', data);
  }

  getWarehouses(shopId) {
    return this.api.get(`/shops/${shopId}/warehouses`);
  }

  getSLAs(shopId) {
    return this.api.get(`/shops/${shopId}/sla`);
  }
}

export default TouchpointsApi;
