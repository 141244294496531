import React from 'react';
import PropTypes from 'prop-types';

import Switch from '../../Wizard/Switch';
import ChooseClaimTypeStep from './ChooseClaimTypeStep';
import ChooseDamageTypeStep from './ChooseDamageTypeStep';
import InvestigationSearchClaimInfoStep from './investigation/InvestigationSearchClaimInfoStep';
import InvestigationShippingInfoStep from './investigation/InvestigationShippingInfoStep';
import InvestigationDeliveryInfoStep from './investigation/InvestigationDeliveryInfoStep';
import InvestigationInvoiceInfoStep from './investigation/InvestigationInvoiceInfoStep';
import PartialDamageSearchClaimInfoStep from './partial-damage/PartialDamageSearchClaimInfoStep';
import PartialDamageWarehouseDeliveryConfirmationStep from './partial-damage/PartialDamageWarehouseDeliveryConfirmationStep';
import PartialDamageShippingInfoStep from './partial-damage/PartialDamageShippingInfoStep';
import PartialDamageDamageInfoStep from './partial-damage/PartialDamageDamageInfoStep';
import PartialDamageInvoiceInfoStep from './partial-damage/PartialDamageInvoiceInfoStep';
import WholeDamageSearchClaimInfoStep from './whole-damage/WholeDamageSearchClaimInfoStep';
import WholeDamageWarehouseDeliveryConfirmationStep from './whole-damage/WholeDamageWarehouseDeliveryConfirmationStep';
import WholeDamageShippingInfoStep from './whole-damage/WholeDamageShippingInfoStep';
import WholeDamageDamageInfoStep from './whole-damage/WholeDamageDamageInfoStep';
import WholeDamageInvoiceInfoStep from './whole-damage/WholeDamageInvoiceInfoStep';
import MissingItemSearchClaimInfoStep from './missing-item/MissingItemSearchClaimInfoStep';
import MissingItemWarehouseDeliveryConfirmationStep from './missing-item/MissingItemWarehouseDeliveryConfirmationStep';
import MissingItemShippingInfoStep from './missing-item/MissingItemShippingInfoStep';
import MissingItemInfoStep from './missing-item/MissingItemInfoStep';
import MissingItemInvoiceInfoStep from './missing-item/MissingItemInvoiceInfoStep';
import { getClaimPastDeadlines } from './claimsHelpers';
import { useClaimsShopCarriers } from './common/useClaimsShopCarriers';
import { useSubmitClaimWorkflow } from '../../claims-center/submit-claims/useSubmitClaimWorkflow';
import OutsideOfDeadlineStep from './common/OutsideOfDeadlineStep';
import InvestigationStepContainer from './investigation/InvestigationStepContainer';
import PartialDamageStepContainer from './partial-damage/PartialDamageStepContainer';
import MissingItemStepContainer from './missing-item/MissingItemStepContainer';
import WholeDamageStepContainer from './whole-damage/WholeDamageStepContainer';

function CreateClaim(props) {
  const {
    claim,
    trackingCode,
    nonClaimableMessage,
    machine,
    onChange = () => {},
    onSubmit,
    resetClaim = () => {},
    onLoadShipmentByTrackingId,
    onResetShipmentSearch,
    onChangeTrackingCode,
    isSubmitting,
    isDisabled,
    isClaimsAutomated,
  } = props;

  const { workflowStep, transition, next, back } = useSubmitClaimWorkflow(machine, claim);

  const onResetClaim = () => {
    resetClaim();
  };

  const onSelectDamage = () => transition('SELECT_DAMAGE');
  const onSelectInvestigation = () => {
    transition('SELECT_INVESTIGATION');
    onChange({ type: 'investigation' });
  };

  const onSelectPartialDamage = () => {
    transition('SELECT_PARTIAL_DAMAGE');
    onChange({ type: 'partial_damage' });
  };

  const onSelectWholeDamage = () => {
    transition('SELECT_WHOLE_DAMAGE');
    onChange({ type: 'whole_damage' });
  };

  const onSelectMissingItem = () => {
    transition('SELECT_MISSING_ITEM');
    onChange({ type: 'missing_item' });
  };

  const onClose = () => props.onClose(workflowStep);

  const { data: carriers = [] } = useClaimsShopCarriers();

  const isShipmentFieldsPrefilled = claim?.shipmentId;

  const { isInvestigationClaimPastDeadline, isDamageOrMissingItemClaimPastDeadline } = getClaimPastDeadlines(
    claim,
    isClaimsAutomated
  );

  const stepProps = {
    claim,
    carriers,
    trackingCode,
    nonClaimableMessage,
    onChange,
    onNext: next,
    onBack: back,
    onClose,
    onResetClaim,
    onResetShipmentSearch,
    onLoadShipmentByTrackingId,
    onChangeTrackingCode,
    isSubmitting,
    isDisabled,
    isClaimsAutomated,
    isAllFieldsEditable: !isShipmentFieldsPrefilled, // TODO: rename isAllFieldsEditable to isManual
    isInvestigationClaimPastDeadline,
    isDamageOrMissingItemClaimPastDeadline,
  };

  return (
    <React.Fragment>
      <Switch selected={workflowStep}>
        <ChooseClaimTypeStep
          key="choose-claim-type"
          name="choose-claim-type"
          onClickDamage={onSelectDamage}
          onClickMissingItem={onSelectMissingItem}
          onClickInvestigation={onSelectInvestigation}
          onClose={onClose}
          isConditionalFlowDisabled={
            isShipmentFieldsPrefilled && !claim.deliveryDate && !claim.postalReturnDeliveryDate
          }
        />
        <ChooseDamageTypeStep
          key="choose-damage-type"
          name="choose-damage-type"
          onClickPartialDamage={onSelectPartialDamage}
          onClickWholeDamage={onSelectWholeDamage}
          onClose={onClose}
        />
        <InvestigationSearchClaimInfoStep
          key="investigation-search-claim-info"
          name="investigation-search-claim-info"
          {...stepProps}
        />
        <OutsideOfDeadlineStep
          key="investigation-outside-of-deadline"
          name="investigation-outside-of-deadline"
          {...stepProps}
          as={InvestigationStepContainer}
        />
        <InvestigationShippingInfoStep
          key="investigation-shipping-info"
          name="investigation-shipping-info"
          {...stepProps}
        />
        <InvestigationDeliveryInfoStep
          key="investigation-delivery-info"
          name="investigation-delivery-info"
          {...stepProps}
        />
        <InvestigationInvoiceInfoStep
          key="investigation-invoice-info"
          name="investigation-invoice-info"
          {...stepProps}
          onNext={onSubmit}
          isSubmitting={isSubmitting}
        />
        <PartialDamageSearchClaimInfoStep
          key="partial-damage-search-claim-info"
          name="partial-damage-search-claim-info"
          {...stepProps}
        />
        <PartialDamageWarehouseDeliveryConfirmationStep
          key="partial-damage-warehouse-delivery-confirmation-info"
          name="partial-damage-warehouse-delivery-confirmation-info"
          {...stepProps}
        />
        <OutsideOfDeadlineStep
          key="partial-damage-outside-of-deadline"
          name="partial-damage-outside-of-deadline"
          {...stepProps}
          as={PartialDamageStepContainer}
        />
        <PartialDamageShippingInfoStep
          key="partial-damage-shipping-info"
          name="partial-damage-shipping-info"
          {...stepProps}
        />
        <PartialDamageDamageInfoStep
          key="partial-damage-damage-info"
          name="partial-damage-damage-info"
          {...stepProps}
        />
        <PartialDamageInvoiceInfoStep
          key="partial-damage-invoice-info"
          name="partial-damage-invoice-info"
          {...stepProps}
          onNext={onSubmit}
        />
        <WholeDamageSearchClaimInfoStep
          key="whole-damage-search-claim-info"
          name="whole-damage-search-claim-info"
          {...stepProps}
        />
        <WholeDamageWarehouseDeliveryConfirmationStep
          key="whole-damage-warehouse-delivery-confirmation-info"
          name="whole-damage-warehouse-delivery-confirmation-info"
          {...stepProps}
        />
        <OutsideOfDeadlineStep
          key="whole-damage-outside-of-deadline"
          name="whole-damage-outside-of-deadline"
          {...stepProps}
          as={WholeDamageStepContainer}
        />
        <WholeDamageShippingInfoStep
          key="whole-damage-shipping-info"
          name="whole-damage-shipping-info"
          {...stepProps}
        />
        <WholeDamageDamageInfoStep key="whole-damage-damage-info" name="whole-damage-damage-info" {...stepProps} />
        <WholeDamageInvoiceInfoStep
          key="whole-damage-invoice-info"
          name="whole-damage-invoice-info"
          {...stepProps}
          onNext={onSubmit}
        />
        <MissingItemSearchClaimInfoStep
          key="missing-item-search-claim-info"
          name="missing-item-search-claim-info"
          {...stepProps}
        />
        <MissingItemWarehouseDeliveryConfirmationStep
          key="missing-item-warehouse-delivery-confirmation-info"
          name="missing-item-warehouse-delivery-confirmation-info"
          {...stepProps}
        />
        <OutsideOfDeadlineStep
          key="missing-item-outside-of-deadline"
          name="missing-item-outside-of-deadline"
          {...stepProps}
          as={MissingItemStepContainer}
        />
        <MissingItemShippingInfoStep
          key="missing-item-shipping-info"
          name="missing-item-shipping-info"
          {...stepProps}
        />
        <MissingItemInfoStep key="missing-item-info" name="missing-item-info" {...stepProps} />
        <MissingItemInvoiceInfoStep
          key="missing-item-invoice-info"
          name="missing-item-invoice-info"
          {...stepProps}
          onNext={onSubmit}
          isSubmitting={isSubmitting}
        />
      </Switch>
    </React.Fragment>
  );
}

CreateClaim.propTypes = {
  claim: PropTypes.object.isRequired,
  machine: PropTypes.shape({}),
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isClaimsAutomated: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isShipmentFieldsPrefilled: PropTypes.bool,
};

export default React.memo(CreateClaim);
