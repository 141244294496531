import { Machine as machine } from 'xstate';
import { isClaimOutsideOfDeadline } from '../../parcel-finder/claims/claimsHelpers';

export default machine({
  key: 'Claims',
  strict: true,
  initial: 'choose-claim-type',
  states: {
    'choose-claim-type': {
      on: {
        SELECT_DAMAGE: 'choose-damage-type',
        SELECT_INVESTIGATION: 'investigation-search-claim-info',
        SELECT_MISSING_ITEM: 'missing-item-search-claim-info',
      },
    },
    'choose-damage-type': {
      on: {
        SELECT_PARTIAL_DAMAGE: 'partial-damage-search-claim-info',
        SELECT_WHOLE_DAMAGE: 'whole-damage-search-claim-info',
      },
    },
    'investigation-search-claim-info': {
      on: {
        NEXT: [
          {
            target: 'investigation-outside-of-deadline',
            cond: isClaimOutsideOfDeadline,
          },
          {
            target: 'investigation-shipping-info',
          },
        ],
      },
    },
    'investigation-outside-of-deadline': {
      on: {
        NEXT: 'investigation-shipping-info',
      },
    },
    'investigation-shipping-info': {
      on: {
        NEXT: [
          {
            target: 'investigation-delivery-info',
            cond: (claim) => !claim.shipmentId || claim.isDelivered,
          },
          {
            target: 'investigation-invoice-info',
          },
        ],
      },
    },
    'investigation-delivery-info': {
      on: {
        NEXT: 'investigation-invoice-info',
      },
    },
    'investigation-invoice-info': {
      type: 'final',
    },
    'whole-damage-search-claim-info': {
      on: {
        NEXT: [
          {
            target: 'whole-damage-warehouse-delivery-confirmation-info',
            cond: (claim) => claim.needsWarehouseDeliveryConfirmation,
          },
          {
            target: 'whole-damage-outside-of-deadline',
            cond: isClaimOutsideOfDeadline,
          },
          {
            target: 'whole-damage-shipping-info',
          },
        ],
      },
    },
    'whole-damage-outside-of-deadline': {
      on: {
        NEXT: 'whole-damage-shipping-info',
      },
    },
    'whole-damage-warehouse-delivery-confirmation-info': {
      on: {
        NEXT: 'whole-damage-shipping-info',
      },
    },
    'whole-damage-shipping-info': {
      on: {
        NEXT: 'whole-damage-damage-info',
      },
    },
    'whole-damage-damage-info': {
      on: {
        NEXT: 'whole-damage-invoice-info',
      },
    },
    'whole-damage-invoice-info': {
      type: 'final',
    },
    'partial-damage-search-claim-info': {
      on: {
        NEXT: [
          {
            target: 'partial-damage-warehouse-delivery-confirmation-info',
            cond: (claim) => claim.needsWarehouseDeliveryConfirmation,
          },
          {
            target: 'partial-damage-outside-of-deadline',
            cond: isClaimOutsideOfDeadline,
          },
          {
            target: 'partial-damage-shipping-info',
          },
        ],
      },
    },
    'partial-damage-warehouse-delivery-confirmation-info': {
      on: {
        NEXT: 'partial-damage-shipping-info',
      },
    },
    'partial-damage-outside-of-deadline': {
      on: {
        NEXT: 'partial-damage-shipping-info',
      },
    },
    'partial-damage-shipping-info': {
      on: {
        NEXT: 'partial-damage-damage-info',
      },
    },
    'partial-damage-damage-info': {
      on: {
        NEXT: 'partial-damage-invoice-info',
      },
    },
    'partial-damage-invoice-info': {
      type: 'final',
    },
    'missing-item-search-claim-info': {
      on: {
        NEXT: [
          {
            target: 'missing-item-warehouse-delivery-confirmation-info',
            cond: (claim) => claim.needsWarehouseDeliveryConfirmation,
          },
          {
            target: 'missing-item-outside-of-deadline',
            cond: isClaimOutsideOfDeadline,
          },
          {
            target: 'missing-item-shipping-info',
          },
        ],
      },
    },
    'missing-item-warehouse-delivery-confirmation-info': {
      on: {
        NEXT: 'missing-item-shipping-info',
      },
    },
    'missing-item-outside-of-deadline': {
      on: {
        NEXT: 'missing-item-shipping-info',
      },
    },
    'missing-item-shipping-info': {
      on: {
        NEXT: 'missing-item-info',
      },
    },
    'missing-item-info': {
      on: {
        NEXT: 'missing-item-invoice-info',
      },
    },
    'missing-item-invoice-info': {
      type: 'final',
    },
  },
});
